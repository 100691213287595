<template>
  <div>
    <div
      v-if="loading"
      class="
        w-100
        mt-5
        mb-5
        d-flex
        flex-column
        align-items-center
        justify-content-center
      "
    >
      <b-spinner variant="custom" class="mb-1" label="Loading..."></b-spinner>
      <span>Processando...</span>
    </div>
    <b-form
      v-if="!loading"
      id="form-principal-editar-lider"
      class="modo-edicao"
      >
      <b-row>
        <b-col class="mb-1">
          <b-form-group
            label="Novo Líder *"
            label-for="novoLider"
            class="m-0"
          >
            <v-select
              id="novoLider"
              v-model="novoLider"
              :options="computedLideresEmpresaGrupo"
              variant="custom"
              item-text="nome"
              item-value="id_colaborador"
              label="nome"
              placeholder="Selecione o novo líder"
            />
          </b-form-group>

          <small class="text-danger">{{ errors.loteColaboradoresNovoLider }}</small>
        </b-col>
      </b-row>

      <b-row align-v="center">
        <b-col cols="12" sm="6">
          <button
            @click="handleCancel"
            type="button"
            class="btn btn-outline-cancel float-right"
          >
            Cancelar
          </button>
        </b-col>
        <b-col cols="12" sm="6">
          <div id="disabled-wrapper-tooltip" class="d-inline-block" tabindex="0">
            <button
              @click="handleSubmit"
              type="button"
              class="btn btn-insert float-left"
              :disabled="confirmarDisabled"
            >
              Salvar
            </button>
          </div>
          <b-tooltip placement="bottom" v-if="confirmarDisabled" show target="disabled-wrapper-tooltip">
            Selecione o novo líder
          </b-tooltip>
        </b-col>
      </b-row>
    </b-form>
  </div>

</template>

<script>
import { BButton, BCol, BForm, BFormGroup, BSpinner, BRow, BTooltip } from 'bootstrap-vue'
import { validation } from "@core/mixins/validation/validation";
import vSelect from 'vue-select'
import { messages } from '@core/mixins/validation/messages'
import { modalGenericModel } from '@/libs/sweetalerts'

export default {
  name: 'PessoaLiderForm',
  components: {
    BFormGroup,
    vSelect,
    BButton,
    BCol,
    BForm,
    BSpinner,
    BRow,
    BTooltip
  },
  props: {
    propLoteColaboradores: {
      type: Array,
      required: true,
    },
    propEmpresaMatriz: {
      type: Number,
      required: true
    },
    liderSelecionadoNoFiltro: {
      type: Array,
      required: true
    }
  },

  mixins: [validation, messages],

  data() {
    return {
      loteColaboradoresAlteraLider: [],
      empresaMatriz: {},
      loading: false,
      novoLider: null,
      errors: {},
      lideresEmpresaGrupo: [],
    }
  },
  mounted() {
    this.loteColaboradoresAlteraLider = this.propLoteColaboradores;
    this.empresaMatriz = this.propEmpresaMatriz;
    this.buscaLideres();
  },
  computed: {
    computedLideresEmpresaGrupo() {
      return this.lideresEmpresaGrupo.filter(
        lider => !this.liderSelecionadoNoFiltro.some(liderFiltro => liderFiltro.id_colaborador === lider.id_colaborador)
      )
    },
    confirmarDisabled() {
      return !this.novoLider;
    },
    getForm() {
      return this.form;
    }
  },
  methods: {
    async handleSubmit() {
      if (this.handleValidation()) {
        this.loading = true;
        this.alterarColaboradorLiderEmLote();
      }
    },
    buscaLideres() {
      this.loading = true;
      let rota = this.$helpers.preparaRotaComMultiplosParametros(
        this.$api.LiderBusca,
        {
          "{grupo}": this.$route.params.idGrupo,
          "{empresa}": this.empresaMatriz,
        }
      );
      this.$http.get(rota).then((res) => {
        this.lideresEmpresaGrupo = res.data;
        this.loading = false;
      }).catch(() => {
        this.handleFormError();
        this.loading = false;
      });
    },
    handleClear() {
      this.errors = {};
    },
    handleCancel() {
      this.handleClear();
      this.$emit("cancel");
    },
    handleValidation() {
      this.strValidate({
        input: this.novoLider,
        message: this.requiredFieldMsg,
        field: "nome",
      })

      this.arrayEmptyValidate("Seleção de colaboradores", this.loteColaboradoresAlteraLider);

      if (!this.formValidationRun()) {
        this.errors = {};
        this.errors = this.formValidationErrors;
        return false;
      }

      return true;
    },
    handleFormError() {
      modalGenericModel(
        'Não foi possível recuperar a relação de líderes, entre em contato com o suporte',
        '',
        require('@/assets/custom-icons/cora-icons/warning.png'),
        'Ok'
      )
    },
    handleModalError(erros) {
      let errosDisplay = ''

      for(let erro of erros){
        errosDisplay += '<br><div>'+erro+'</div>'
      }

      errosDisplay = errosDisplay.substring(4)

      modalGenericModel(
        'Erro ao Salvar Dados',
        errosDisplay,
        require('@/assets/custom-icons/cora-icons/error.png'),
        'Ok'
      )
    },
    handleCloseModal() {
      this.handleCancel()
    },
    alterarColaboradorLiderEmLote() {
      modalGenericModel(
        'Tem certeza que deseja alterar estes usuários?',
        'Essa ação irá afetar o acesso às respostas dos colaboradores selecionados.<br>' +
        'Ao confirmar, o líder atual não terá mais acesso às respostas dos colaboradores e<br>' +
        'o novo líder poderá visualizar todas as respostas, antigas ou novas.',
        require('@/assets/custom-icons/cora-icons/warning.png'),
        'Sim, alterar',
        'Cancelar'
      ).then((result) => {
        if (result.value) {
          try {
            this.$http
              .put(this.$api.AlteraLoteColaboradorLider, {
                ids_colaboradores: this.loteColaboradoresAlteraLider,
                id_lider: this.novoLider.id_colaborador
              })
              .then((res) => {
                if (res.status === 200) {
                  this.handleCloseModal();
                }
              }).catch((error) => {
                let erros = ['Erro de conexão com o servidor, tente novamente mais tarde.'];

                if(error?.response?.data){
                  erros = Object.values(error.response.data.errors);
                }

                this.handleModalError(erros);
                this.loading = false;
              });
          } catch (e) {}
        } else {
          this.loading = false;
        }
      });
    },
  }
}
</script>

<style scoped>
.btn-insert {
  background: linear-gradient(45deg, #7030a0 0%, #a369ce 100%);
  color: #fff;
  transition: 0.3s background-color;
}

.btn-insert:hover {
  background: linear-gradient(45deg, #60248d 0%, #9950d0 100%);
  color: #fff;
}

.btn-outline-cancel {
  background-color: transparent;
  border: solid 0.094rem #82868b;
  color: #82868b;
  transition: 0.3s;
}

.btn-outline-cancel:hover {
  text-decoration: underline;
}

@media (max-width: 576px) {
  .btn-insert,
  .btn-outline-cancel {
    width: 100%;
    margin-bottom: 15px;
  }
}
</style>
