<template>
  <div
    id="ComponentePessoaForm"
    class="p-2 card"
  >
    <h1>{{ formValues.nome }}</h1>

    <b-alert variant="success" show>
      <div v-if="confirmaAtualizacao" class="alert-body">
        <span
          ><strong class="color-msg-success"
            >Dados atualizados com sucesso!
          </strong></span
        >
      </div>
    </b-alert>
    <b-alert variant="danger" show>
      <div v-if="erros != null" class="alert-body">
        <strong>Registro não efetuado </strong>
        <ul id="array-with-index">
          <li v-for="erro in erros" :key="erro.errors">
            {{ erro }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-overlay :show="loadingShow" rounded="lg" opacity="0.4">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row class="mb-1">
            <b-col md="6" xl="3" class="mb-1">
              <validation-provider
                name="CPF"
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false : true"
                  label="CPF *"
                  label-for="Pessoa-CPF"
                >
                  <Cleave
                    id="Pessoa-CPF"
                    class="form-control"
                    :options="mask.cpf"
                    v-model="formValues.cpf"
                    :disabled="formDisabled.cpf"
                  />

                  <small v-if="cpfExiste" class="text-danger"
                    >CPF já consta em nosso sistema<br
                  /></small>
                  <small v-if="errors.length > 0" class="text-danger"
                    >{{ errors[0] }} <br
                  /></small>
                  <small
                    v-if="!cpfValido && formValues.cpf != ''"
                    class="text-danger"
                    >CPF inválido</small
                  >
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <validation-provider
                name="Nome"
                #default="{ errors }"
                rules="required|min:6|max:200|hasValidSpace"
              >
                <b-form-group label="Nome Completo *" label-for="Pessoa-Nome">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="formValues.nome"
                    id="nome"
                    name="Pessoa-Nome"
                    :disabled="formDisabled.nome"
                  />
                  <small v-if="errors.length > 0" class="text-danger">{{
                      errors[0]
                    }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <empresaSelect
                @getEmpresa="getEmpresa"
                :values="formValues.empresa"
                :idEmpresa="idEmpresa"
                :required="true"
                :disabledProp="formDisabled.empresa"
              />
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <liderSelect
                @getLider="getLider"
                :values="formValues.lider"
                :idLider="idLider"
                :idEmpresa="idEmpresa"
                :required="LiderRequired"
                :disabledProp="formDisabled.lider"
                :modoVisualizacao="false"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <PerfisSelect
                @GetPerfil="getPerfil"
                :values="formValues.perfil"
                :disabledProp="formDisabled.perfil"
              />
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <validation-provider
                name="Email"
                #default="{ errors }"
                :rules="emailRequired ? 'required|email' : 'email'"
              >
                <b-form-group label="Email *" label-for="Pessoa-email">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="formValues.email"
                    id="email"
                    name="Pessoa-email"
                    :disabled="formDisabled.email"
                    Type="email"
                  />
                  <small v-if="errors.length > 0" class="text-danger">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <validation-provider
                name="telefone"
                #default="{ errors }"
                rules="length:11"
              >
                <b-form-group
                  :state="errors.length > 0 ? false : null"
                  label="Telefone"
                  label-for="Pessoa-telefone"
                >
                  <Cleave
                    v-model="formValues.telefone"
                    id="telefone"
                    class="form-control"
                    :options="mask.fone"
                    name="Pessoa-telefone"
                    :disabled="formDisabled.telefone"
                    placeholder="(00) 0 0000 0000"
                  />
                  <small v-if="errors.length > 0" class="text-danger">{{
                    "Telefone Inválido"
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <validation-provider
                name="nascimento"
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  label="Data de nascimento *"
                  label-for="Pessoa-nascimento"
                >
                  <b-form-input
                    v-model="formValues.dtNascimento"
                    :state="errors.length > 0 ? false : null"
                    id="dtNascimento"
                    name="Pessoa-nascimento"
                    :disabled="formDisabled.dtNascimento"
                    placeholder="01/01/2000"
                    type="date"
                  />
                  <small v-if="errors.length > 0" class="text-danger">{{
                      errors[0]
                    }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <generoSelect
                @getGenero="getGenero"
                :values="formValues.genero"
                :idGenero="idGenero"
                :required="false"
                :disabledProp="formDisabled.genero"
              />
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <estadoCivilSelect
                @getEstadoCivil="getEstadoCivil"
                :values="formValues.estadoCivil"
                :idEstadoCivil="idEstadoCivil"
                :required="false"
                :disabledProp="formDisabled.estadoCivil"
              />
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <validation-provider
                name="Número de filhos"
                #default="{ errors }"
                rules="between:0,30"
              >
                <b-form-group
                  label="Número de filhos"
                  label-for="Pessoa-filhos"
                >
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    @keypress="onlyNumber"
                    v-model="formValues.filhos"
                    id="filhos"
                    name="Pessoa-filhos"
                    :disabled="formDisabled.filhos"
                  />
                  <small v-if="errors.length > 0" class="text-danger">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <escolaridadeSelect
                @getEscolaridade="getEscolaridade"
                :values="formValues.escolaridade"
                :idEscolaridade="idEscolaridade"
                :required="false"
                :disabledProp="formDisabled.escolaridade"
              />
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col md="6" xl="3" class="mb-1">
              <validation-provider
                name="admissao"
                #default="{ errors }"
              >
                <b-form-group
                  label="Data de Admissão"
                  label-for="Pessoa-admissao"
                >
                  <b-form-input
                    v-model="formValues.dtAdmissao"
                    :state="errors.length > 0 ? false : null"
                    id="admissao"
                    name="Pessoa-admissao"
                    :disabled="formDisabled.dtAdmissao"
                    placeholder="01/01/2000"
                    type="date"
                  />
                  <small v-if="errors.length > 0" class="text-danger">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <regimeSelect
                @getRegime="getRegime"
                :values="formValues.regime"
                :idRegime="idRegime"
                :required="false"
                :disabledProp="formDisabled.regime"
                :modoVisualizacao="false"
              />
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <validation-provider
                name="Renda"
                #default="{ errors }"
                rules="renda-bruta-colaborador"
              >
                <b-form-group
                  :state="errors.length > 0 ? false : null"
                  label="Renda Bruta"
                  label-for="Pessoa-renda"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>R$</b-input-group-prepend>
                    <cleave
                      id="renda"
                      v-model="formValues.renda"
                      class="form-control"
                      :options="mask.renda"
                      name="Renda"
                      placeholder="0,00"
                      :disabled="formDisabled.renda"
                    />
                  </b-input-group>
                  <small
                    v-if="errors.length > 0 || formValues.renda < 0"
                    class="text-danger"
                    >{{ errors[0] }}</small
                  >
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <turnoSelect
                @getTurno="getTurno"
                :values="formValues.turno"
                :idTurno="idTurno"
                :required="false"
                :disabledProp="formDisabled.turno"
              />
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <funcaoSelect
                @getFuncao="getFuncao"
                :values="formValues.funcao"
                :idFuncao="idFuncao"
                :required="false"
                :disabledProp="formDisabled.funcao"
              />
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <setorSelect
                @getSetor="getSetor"
                :values="formValues.setor"
                :idSetor="idSetor"
                :required="false"
                :disabledProp="formDisabled.setor"
              />
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <gerenciaSelect
                @getGerencia="getGerencia"
                :values="formValues.gerencia"
                :idGerencia="idGerencia"
                :required="false"
                :disabledProp="formDisabled.gerencia"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <diretoriaSelect
                @getDiretoria="getDiretoria"
                :values="formValues.diretoria"
                :idDiretoria="idDiretoria"
                :required="false"
                :disabledProp="formDisabled.diretoria"
              />
            </b-col>
            <b-col md="6" xl="3" class="mb-1">
              <unidadeSelect
                @getUnidade="getUnidade"
                :values="formValues.unidade"
                :idUnidade="idUnidade"
                :required="false"
                :disabledProp="formDisabled.unidade"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" align="center">
              <b-button
                type="reset"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="mr-1"
                @click.prevent="redirecionaParaListagem"
              >
                Desfazer
              </b-button>

              <b-button
                type="submit"
                variant="custom"
                @click.prevent="updatePessoa"
              >
                Salvar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, between, min, max, length, email } from "@validations";

import PerfisSelect from "@/views/components/custom/pessoas/PerfilSelect.vue";
import GeneroSelect from "@/views/components/custom/pessoas/GeneroSelect.vue";

import estadoCivilSelect from "@/views/components/custom/pessoas/EstadoCivilSelect.vue";
import regimeSelect from "@/views/components/custom/pessoas/RegimeSelect.vue";
import escolaridadeSelect from "@/views/components/custom/pessoas/EscolaridadeSelect.vue";
import turnoSelect from "@/views/components/custom/pessoas/TurnoSelect.vue";
import funcaoSelect from "@/views/components/custom/pessoas/FuncaoSelect.vue";
import gerenciaSelect from "@/views/components/custom/pessoas/GerenciaSelect.vue";
import diretoriaSelect from "@/views/components/custom/pessoas/DiretoriaSelect.vue";
import setorSelect from "@/views/components/custom/pessoas/SetorSelect.vue";
import unidadeSelect from "@/views/components/custom/pessoas/UnidadeSelect.vue";
import empresaSelect from "@/views/components/custom/pessoas/EmpresaGrupoSelect.vue";
import liderSelect from "@/views/components/custom/pessoas/LiderSelect.vue";
import Cleave from "vue-cleave-component";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";

import {
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BCardText,
  BAlert,
  BOverlay,
  BModal,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";

extend("hasValidSpace", {
  message: "Nome e Sobrenome",
  validate: value => {
    const regex = /\b\w+\s+\w+/;
    return regex.test(value);
  }
});

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BCardText,
    BAlert,
    BOverlay,
    BModal,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    PerfisSelect,
    estadoCivilSelect,
    escolaridadeSelect,
    regimeSelect,
    turnoSelect,
    funcaoSelect,
    gerenciaSelect,
    diretoriaSelect,
    setorSelect,
    unidadeSelect,
    liderSelect,
    empresaSelect,
    GeneroSelect,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      userData: getUserData(),
      required,
      between,
      min,
      max,
      length,
      email,
      Pessoa: "",
      emailRequired: true,
      LiderRequired: true,

      formValues: {
        cpf: "",
        nome: "",
        perfil: [],
        email: "",
        telefone: "",
        dtNascimento: "",
        genero: {},
        estadoCivil: {},
        filhos: 0,
        escolaridade: {},
        dtAdmissao: "",
        regime: {},
        renda: "",
        turno: {},
        funcao: {},
        setor: {},
        gerencia: {},
        diretoria: {},
        lider: {},
        empresa: {},
        unidade: {},
      },
      formDisabled: {
        cpf: false,
        perfil: false,
        renda: false,
        dtAdmissao: false,
        filhos: false,
        genero: false,
        dtNascimento: false,
        telefone: false,
        email: true,
        nome: false,
        gerencia: false,
        diretoria: false,
        lider: false,
        empresa: false,
        funcao: false,
        setor: false,
        turno: false,
        regime: false,
        escolaridade: false,
        estadoCivil: false,
        unidade: false,
      },

      idEstadoCivil: 0,
      idEscolaridade: 0,
      idRegime: 0,
      idTurno: 0,
      idFuncao: 0,
      idSetor: 0,
      idGerencia: 0,
      idDiretoria: 0,
      idUnidade: 0,
      idEmpresa: 0,
      idLider: 0,
      idGenero: 0,

      loadingShow: true,
      erros: null,
      confirmaCadastro: false,
      confirmaAtualizacao: false,
      cpfValido: true,
      cpfExiste: false,
      cpfError: false,

      mask: {
        cpf: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
        },
        fone: {
          delimiters: ["(", ")", " ", " ", " "],
          blocks: [0, 2, 1, 4, 4],
          numericOnly: true,
        },
        dtNascimento: {
          delimiters: ["/", "/"],
          blocks: [2, 2, 4],
          numericOnly: true,
        },
        renda: {
          numeral: true,
          numeralDecimalScale: 2,
          signBeforePrefix: true,
          numeralDecimalMark: ",",
          delimiter: "",
        },
      },
    };
  },

  props: {
    propEditPessoa: {
      type: Object,
      required: true
    }
  },

  async mounted() {
    await this.buscaDadosPessoaEditar()
  },

  methods: {
    pessoaVisualizada(id_pessoa_visualizada) {
      let form = {'id_pessoa' : this.userData.pessoa.id_pessoa,'id_pessoa_visualizada' : id_pessoa_visualizada}
        this.$http
          .post(
            this.$api.PessoaVisualizada(),
            form
          );
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },

    setaDadosFormulario(dados) {
      this.idEmpresa = dados.id_empresa;
      this.formValues.nome = dados.nome;
      this.formValues.cpf = dados.cpf;
      this.formValues.telefone = dados.telefone;
      this.formValues.dtNascimento = dados.nascimento;
      this.formValues.email = dados.email;
      this.formValues.filhos = dados.numero_filhos;
      this.formValues.renda = dados.renda_bruta;
      this.formValues.dtAdmissao = dados.admissao;
      this.idGenero = dados.id_genero;
      this.idEstadoCivil = dados.id_estado_civil;
      this.idEscolaridade = dados.id_escolaridade;
      this.idRegime = dados.id_regime;
      this.idTurno = dados.id_empresa_turno;
      this.idFuncao = dados.id_empresa_funcao;
      this.idSetor = dados.id_empresa_setor;
      this.idGerencia = dados.id_empresa_gerencia;
      this.idDiretoria = dados.id_empresa_diretoria;
      this.idUnidade = dados.id_empresa_unidade;
      this.idLider = dados.id_lider;
    },

    async buscaDadosPessoaEditar() {
      let rotacolab = this.$helpers.preparaRotaComParametros(
        this.$api.BuscaDadosPessoa,
        "{colaborador}",
        this.propEditPessoa.id_colaborador
      );

      await this.$http.get(rotacolab)
      .then(async (res) => {
        if (res.status == 200) {
          this.setaDadosFormulario(res.data[0]);
          this.pessoaVisualizada(res.data[0].id_pessoa);
          await this.buscaPerfisColab(this.propEditPessoa.id_colaborador);
          this.formDisabled.empresa = true;
          this.formDisabled.cpf = true;
        }
      })
      this.loadingShow = false;
    },

    async buscaPerfisColab(id_colab) {
      let rotaPerfis = this.$helpers.preparaRotaComParametros(
        this.$api.BuscaPerfisColab,
        "{colaborador}",
        id_colab
      );

      await this.$http.get(rotaPerfis)
      .then((res) => {
        if (res.status == 200) {
          this.formValues.perfil = res.data;
          this.verificaPerfilEmail(res.data);
        }
      })
    },

    formataDadosFormulario() {
      let idColaborador = this.propEditPessoa.id_colaborador;

      let perfis = this.$helpers.retornaAtributoArray(
        this.formValues.perfil,
        "id_perfil"
      );

      return {
        id_colaborador: idColaborador,
        cpf: this.formValues.cpf,
        email: this.formValues.email,
        nome: this.formValues.nome,
        id_perfil: perfis,
        telefone: this.formValues.telefone,
        nascimento: this.formValues.dtNascimento,
        id_genero: this.formValues.genero
          ? this.formValues.genero.id_genero
          : "",
        id_estado_civil: this.formValues.estadoCivil
          ? this.formValues.estadoCivil.id_estado_civil
          : "",
        numero_filhos: this.formValues.filhos,
        id_escolaridade: this.formValues.escolaridade
          ? this.formValues.escolaridade.id_escolaridade
          : "",
        admissao: this.formValues.dtAdmissao,
        id_regime: this.formValues.regime
          ? this.formValues.regime.id_regime
          : "",
        renda_bruta: this.formValues.renda,
        id_empresa_turno: this.formValues.turno
          ? this.formValues.turno.id_empresa_turno
          : "",
        id_empresa_funcao: this.formValues.funcao
          ? this.formValues.funcao.id_empresa_funcao
          : "",
        id_empresa_setor: this.formValues.setor
          ? this.formValues.setor.id_empresa_setor
          : "",
        id_empresa_gerencia: this.formValues.gerencia
          ? this.formValues.gerencia.id_empresa_gerencia
          : "",
        id_empresa_diretoria: this.formValues.diretoria
          ? this.formValues.diretoria.id_empresa_diretoria
          : "",
        id_lider: this.formValues.lider
          ? this.formValues.lider.id_colaborador
          : "",
        id_empresa: this.formValues.empresa
          ? this.formValues.empresa.id_empresa
          : "",
        id_empresa_unidade: this.formValues.unidade
          ? this.formValues.unidade.id_empresa_unidade
          : "",
        id_empresa_grupo: this.$route.params.idGrupo,
      };
    },

    async atualizarDados() {
      this.loadingShow = true;
      let form = this.formataDadosFormulario();

      let url = this.$helpers.preparaRotaComParametros(
        this.$api.UpdatePessoa,
        "{idColaborador}",
        this.propEditPessoa.id_colaborador
      );

      await this.$http.put(url, form)
      .then((res) => {
        if (res.status == 200) {
          this.confirmaAtualizacao = true;
          this.erros = null;

        }
      })
      .catch((error) => {
        this.confirmaAtualizacao = false;
        this.handleApiError(error)
      });

      this.loadingShow = false;
    },

    handleApiError(error) {
      let texto = ['Erro de conexão com o servidor. Tente novamente mais tarde.']
      if(
        error.response &&
        Object.keys(error.response.data).length !== 0
      ){
        texto = [];

        if(error.response.data.error.Lider){

          texto.push(error.response.data.error.Lider);
        }else{
          for(let value of Object.values(error.response.data.errors)){
            texto.push(value[0]);
          }
        }
      }

      this.erros = texto
    },

    async updatePessoa() {
      await this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.atualizarDados();
          setTimeout(() => {
            this.confirmaAtualizacao = false;
          }, 5000);
        }
      });
    },

    verificaPerfilEmail(perfis) {
      let verificaTrab = perfis.filter((x) => x.descricao == "Trabalhador");

      if (verificaTrab.length != 0 && perfis.length == 1) {
        this.emailRequired = false;
        this.LiderRequired = true;
        this.formDisabled.email = true;
        this.formValues.email = "";
      } else {
        this.emailRequired = true;
        this.LiderRequired = false;
        this.formDisabled.email = false;
      }
    },

    redirecionaParaListagem() {
      this.$emit("trocaComponente", {
        componente: 'GrupoPessoas',
        subComponente: 'GrupoPessoasListar'
      });
    },

    getEstadoCivil(estadoCivilRecebido) {
      this.formValues.estadoCivil = estadoCivilRecebido;
    },
    getEscolaridade(Estadoescolaridade) {
      this.formValues.escolaridade = Estadoescolaridade;
    },
    getRegime(Estadoregime) {
      this.formValues.regime = Estadoregime;
    },
    getTurno(Estadoturno) {
      this.formValues.turno = Estadoturno;
    },

    getGerencia(Estadogerencia) {
      this.formValues.gerencia = Estadogerencia;
    },

    getFuncao(Estadofuncao) {
      this.formValues.funcao = Estadofuncao;
    },

    getDiretoria(Estadodiretoria) {
      this.formValues.diretoria = Estadodiretoria;
    },

    getSetor(Estadosetor) {
      this.formValues.setor = Estadosetor;
    },

    getUnidade(Estadounidade) {
      this.formValues.unidade = Estadounidade;
    },

    getLider(Estadolider) {
      this.formValues.lider = Estadolider;
    },

    getPerfil(Estadoperfil) {
      this.verificaPerfilEmail(Estadoperfil);
      this.formValues.perfil = Estadoperfil;
    },

    getEmpresa(Estadoempresa) {
      this.formValues.empresa = Estadoempresa;
    },

    getGenero(Estadogenero) {
      this.formValues.genero = Estadogenero;
    },
  }
};
</script>

<style>
#ComponentePessoaForm .itens > span {
  display: inline-block;
  background: #2772c0;
  margin: 0 13px 3px 0;
  padding: 3px 5px;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}
#ComponentePessoaForm .itens > span:last-child {
  margin-right: 0;
}
</style>
